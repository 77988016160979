// @flow
import React, { Component } from 'react';
import { View, Text } from 'react-native';
import { connect }   from 'react-redux';
import { setNavButtons } from 'reducers/navbar.js';
import { login }     from 'actions/session';
import styled        from 'styled-components/native';
import snip          from 'styles/snip';
import colors        from 'styles/colors';
import Input         from 'components/Input';
import SceneCommon   from 'containers/SceneCommon';
import store         from '../../store.js';
import * as SESS     from 'actions/session';
import { SET_HISTORY_SECTION_INDEX, SET_USER_VISITED_HISTORY} from 'constants.js'

const StyledLabel = styled(Text)`
  font-size: 1.2rem;
  font-weight: bold;
  padding: 0.5em;
  margin-top: 1.5rem;
`;

const Message = styled(Text)`
  text-align: center;
  font-size: 1.2rem;
  margin-top: 1rem;
  padding: 0.3rem;
  white-space: pre-wrap;
`;
const LinkText = styled(Text)`
  color: ${colors.blue};
  padding: 10px 0px;
  font-weight: 600;
  font-size: 1rem;
  text-align: center;
  display: inline-block;
`;

const CenteredContainer = styled(View)`
  align-items: center;
  width: 100%;
  margin-top: 7vh;
`;
const LoginBtnWrapper = styled(View)`
  padding: ${snip.pad};
  align-items: center;
`;


type Props = {
	history: Object,
	login:   Function,
	setNavButtons: Function,
};

type State = {
	email:      string,
	password:   string,
};

class Login extends Component {

	state: State;
	props: Props;

	constructor(props: Props) {

		super(props);

		this.state = {
			email:    '',
			password: '',
		};
	}

	componentWillUnmount() {
		this.props.setNavButtons(null);
	}

  	componentDidMount() {
		// this.props.setNavButtons(['back', 'Login']);
		SESS.authRefresh(store)
		.then((auth) => {
			this.props.history.push('/inspect');
		})
		.catch((err) => {
			console.error({ authRefresh: err });
		})
	}

	handleSubmit = (evt) => {

		this.props.login({
			email:		this.state.email,
			password: this.state.password,
		})
		.then(() => {
			const { setSectionIndex, history, setUserAccesingHistory } = this.props;
			setUserAccesingHistory(false);
			setSectionIndex(0);
			history.push('/inspect');
		});
	};

	render() {

		const { email, password } = this.state;
		const msg = 'Passwords default to your 10-digit \nmobile phone number.';
		const styleBtn = {
			fontFamily:      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
			fontWeight:      'bold',
			fontSize:        '1.7rem',
			borderStyle:     'none',
			borderRadius:    '3px',
			padding:         snip.pad,
			backgroundColor: colors.green_primary,
			color:           'white',
			width:			 '60%'
		};

		const wrapperStyle = {
			display: 'flex',
			alignItems: 'center',
			gap: '3.5rem'
		}

		const inputStyle = {
			backgroundColor: 'transparent',
			border: 'none',
			marginBottom: '1.5rem'
		}

		return (
			<SceneCommon style={wrapperStyle}>
				<Message>{msg}</Message>
				<View style={{width: '90%'}}>
					<form onSubmit={(evt) => {
						evt.preventDefault();
						this.handleSubmit(evt);
					}}>
						<StyledLabel>Email</StyledLabel>
						<Input
							placeholder="you@example.com"
							onChangeText={(value) => this.setState({ email: value })}
							value={email}
							style={inputStyle}
							fromloginpage={true}
						/>
						<StyledLabel>Password</StyledLabel>
						<Input
							type="password"
							placeholder="••••••"
							onChangeText={(value) => this.setState({ password: value })}
							value={password}
							style={inputStyle}
							fromloginpage={true}
						/>
						<CenteredContainer>
							<LinkText onPress={() => this.props.history.push('/forgotPassword')}>Forgot Password</LinkText>
						</CenteredContainer>
						<LoginBtnWrapper>
							<input type="submit" value="Login" style={styleBtn} />
						</LoginBtnWrapper>
					</form>
				</View>

			</SceneCommon>
		);
	}
}

export default connect(null,
	{ 
		login,
		setNavButtons,
		setSectionIndex: (index) => {return (dispatch) => dispatch({type: SET_HISTORY_SECTION_INDEX, index})},
		setUserAccesingHistory: (usingHistoryTab) => {return (dispatch) => dispatch({type: SET_USER_VISITED_HISTORY, usingHistoryTab})}
	})(Login);
